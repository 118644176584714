import React, { useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./AnimatedContainer.scss";

export function AnimatedContainer({ children, ...rest }) {
  const initAnimation = () => {
    const scene = new THREE.Scene();

    const aspect = window.innerWidth / window.innerHeight;
    const d = 2;
    const camera = new THREE.OrthographicCamera(-d * aspect, d * aspect, d, -d, 1, 1000);

    camera.position.set(4, 3, 3); // all components equal
    camera.lookAt(scene.position); // or the origin

    const renderer = new THREE.WebGLRenderer({ antialias: false });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0x141a35);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMapEnabled = true;
    renderer.shadowMapType = THREE.PCFSoftShadowMap;

    const loader = new THREE.JSONLoader();

    function handle_load(geometry, materials) {
      const obj = new THREE.Mesh(geometry, materials);
      obj.receiveShadow = true;
      obj.castShadow = true;
      scene.add(obj);
    }

    loader.load("https://glversecloud-2-assets.glversecloud.com/assets/js/room.json", handle_load);

    const screen = new THREE.Mesh(
      new THREE.PlaneGeometry(0.31, 0.25, 0.85),
      new THREE.MeshStandardMaterial({ emissive: 0x141a35 })
    );
    screen.position.set(1.8, 0.84, 1.32);
    scene.add(screen);

    const snow = [];
    const nb = 35;

    for (let i = 0; i < nb; i++) {
      const particle = new Snow();
      particle.init();
      particle.modelize();
      snow.push(particle);
    }

    light();

    function light() {
      const spotlight = new THREE.SpotLight(0xf5fc5a);
      spotlight.position.set(1.75, 4, -3);
      spotlight.castShadow = true;
      spotlight.intensity = 0.2;
      scene.add(spotlight);

      const dirlight = new THREE.DirectionalLight(0xfdd8ff);
      dirlight.position.set(-0.96, 3, -0.75);
      //dirlight.castShadow = true;
      dirlight.intensity = 0.2;
      scene.add(dirlight);

      const ambi = new THREE.AmbientLight(0x0e1642);
      scene.add(ambi);

      const pointlight = new THREE.PointLight();
      pointlight.position.set(0.63, 0.72, 0.71);
      //pointlight.castShadow = true;
      pointlight.intensity = 0.2;
      scene.add(pointlight);
    }

    function update() {
      for (let i = 0; i < nb; i++) {
        snow[i].update();
      }

      renderer.render(scene, camera);
      requestAnimationFrame(update);
    }

    function Snow() {
      this.position = new THREE.Vector3();
      this.vel = new THREE.Vector3(
        -1 * (0.0005 + Math.random() * 0.001),
        -1 * (0.005 + Math.random() * 0.01),
        -0.1 * (0.005 + Math.random() * 0.01)
      );

      this.init = function() {
        this.position.x = Math.random() * 2.85;
        this.position.y = 2.6;
        this.position.z = -2.47 + Math.random() * 2;
      };

      this.modelize = function() {
        this.mesh = new THREE.Mesh(
          new THREE.DodecahedronGeometry(1),
          new THREE.MeshPhongMaterial({
            color: 0xfafafa
          })
        );
        this.mesh.position.copy(this.position);
        this.mesh.scale.set(0.02, 0.02, 0.02);
        this.mesh.castShadow = true;
        this.mesh.receiveShadow = true;
        scene.add(this.mesh);
      };

      this.update = function() {
        if (this.position.y < 0) this.position.y = 2.6;
        if (this.position.x < 0 || this.position.x > 2.85) this.vel.x *= -1;
        if (this.position.z < -2.47 || this.position.z > -0.47) this.vel.z *= -1;
        this.position.add(this.vel);
        this.mesh.position.copy(this.position);
      };
    }

    document.querySelector("#webGL-container").append(renderer.domElement);
    renderer.render(scene, camera);

    update();

    // particles :
    // y = 2.60
    // x > 0 , x < 2.85
    // z < 0.42 z > -2.47
  };

  useEffect(() => {
    initAnimation();
  }, []);

  return (
    <div id="webGL-container">
      <div className={styles.container}>{children}</div>
    </div>
  );
}

AnimatedContainer.propTypes = {
  children: PropTypes.node
};
