import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as ReactCarousel } from "react-responsive-carousel";
import { FormattedMessage } from "react-intl";
import { Column } from "./Column";

import styles from "./Carousel.scss";

export function Carousel({ children, className, ...rest }) {
  return (
    <ReactCarousel className={classNames(styles.carousel, className)} {...rest}>
      {children}
    </ReactCarousel>
  );
}

Carousel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
